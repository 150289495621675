import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import isMobile from "ismobilejs";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageTypeConsumer } from "../components/context/page-type";

// Components
import { DirectorVideo } from "../components/videos/director-video";

const Page = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  background-color: #1d1d1b;

  color: #fff;

  @media (max-width: 768px) {
    padding: 150px 0 0 0;
  }
`;

const TopContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: dense;

  width: 100%;

  order: ${props => props.desktopOrder};
  @media (max-width: 768px) {
    order: ${props => props.mobileOrder};
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: dense;

  width: 100%;

  order: ${props => props.desktopOrder};
  @media (max-width: 768px) {
    order: ${props => props.mobileOrder};
  }
`;

const TextContainer = styled.div`
  width: 100%;
  text-align: center;

  padding: 55px 0 63px 0;

  & button.bio-button {
    font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande", sans-serif;
    font-size: 18px;
    line-height: 151%;
    letter-spacing: 0.1em;
    font-variant: small-caps;
    color: rgba(253, 252, 252, 0.8);

    @media (max-width: 1080px) {
      font-size: 12px;
      line-height: 15px;
    }
  }

  & .director-name {
    width: 100%;
    text-align: center;

    & h1 {
      font-size: 72px;
      line-height: 115%;

      @media (max-width: 1300px) {
        font-size: 28px;
        line-height: 36px;
      }
    }
  }

  order: ${props => props.desktopOrder};
  @media (max-width: 768px) {
    order: ${props => props.mobileOrder};
    padding: 0 0 25px 0;
  }
`;

const BiographyContainer = styled.div`
  width: 100%;
  max-width: 680px;

  margin: 0 auto;
  padding: 130px 20px 0 20px;

  text-align: left;

  font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande", sans-serif;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.04em;

  color: #fcfbfc;

  & .biography-text {
    max-width: 675px;
    margin: 0 auto;

    & p:first-of-type {
      margin-top: 0;
    }

    & p:last-of-type {
      margin-bottom: 0;
    }
  }

  order: ${props => props.desktopOrder};
  @media (max-width: 768px) {
    order: ${props => props.mobileOrder};
  }
`;

const PhotographerThumbnail = styled.div`
  width: 100%;
  margin: 60px 0 0 0;

  & img {
    border-radius: 100%;

    width: 200px;
    height: 200px;
    margin: 0 auto;

    object-fit: cover;
  }
`;

const DirectorContent = ({ data, setPageType }) => {
  const [directorBio, setDirectorBio] = useState("");
  const [isMobileDevice, setIsMobileDevice] = useState(true);

  useEffect(() => {
    setPageType("directors");

    if (typeof window !== undefined) {
      setDirectorBio(document.getElementById("biography"));
      setIsMobileDevice(isMobile(window.navigator.userAgent).any);
    }
  }, []);

  const firstDirectorVideo = data.prismicDirector.data.videos
    .filter((content, index) => index === 0)
    .filter(content => content.video.document !== null)
    .map((content, index) => (
      <DirectorVideo
        key={`director_video_${data.prismicDirector.uid}_${index}`}
        content={content}
        index={index}
        directorUid={data.prismicDirector.uid}
        isMobileDevice={isMobileDevice}
      />
    ));

  const remainingDirectorVideos = data.prismicDirector.data.videos
    .filter((content, index) => index !== 0)
    .filter(content => content.video.document !== null)
    .map((content, index) => (
      <DirectorVideo
        key={`director_video_${data.prismicDirector.uid}_${index}_remaining_videos`}
        content={content}
        index={index}
        directorUid={data.prismicDirector.uid}
        isMobileDevice={isMobileDevice}
      />
    ));

  return (
    <>
      <Helmet
        title={`${data.prismicDirector.data.title.text} – Park Village`}
        meta={[
          {
            name: "og:title",
            content: `${data.prismicDirector.data.title.text} – Park Village`,
          },
          {
            name: "twitter:title",
            content: `${data.prismicDirector.data.title.text} – Park Village`,
          },
        ]}
      />
      <Page>
        <TopContainer mobileOrder={2} desktopOrder={1}>
          {firstDirectorVideo}
        </TopContainer>

        <TextContainer mobileOrder={1} desktopOrder={2}>
          <div
            className="director-name"
            dangerouslySetInnerHTML={{
              __html: data.prismicDirector.data.title.html,
            }}
          />

          <button
            className="bio-button"
            onClick={() =>
              directorBio.scrollIntoView({ behavior: "smooth", block: "start" })
            }
          >
            BIO
          </button>
        </TextContainer>

        <Container mobileOrder={3} desktopOrder={3}>
          {remainingDirectorVideos}
        </Container>

        <BiographyContainer id="biography" mobileOrder={4} desktopOrder={4}>
          <div
            className="biography-text"
            dangerouslySetInnerHTML={{
              __html: data.prismicDirector.data.bio.html,
            }}
          />

          {data.prismicDirector.data.portrait.fluid !== null && (
            <PhotographerThumbnail>
              <img
                srcSet={data.prismicDirector.data.portrait.fluid.srcSetWebp}
                src={data.prismicDirector.data.portrait.fluid.srcWebp}
                alt={data.prismicDirector.data.portrait.alt}
                loading="lazy"
              />
            </PhotographerThumbnail>
          )}
        </BiographyContainer>
      </Page>
    </>
  );
};

const Director = ({ data }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <DirectorContent setPageType={setPageType} data={data} />
    )}
  </PageTypeConsumer>
);

export default withPreview(Director);

export const query = graphql`
  query Director($uid: String!) {
    prismicDirector(uid: { eq: $uid }) {
      uid
      prismicId
      data {
        portrait {
          alt
          fluid {
            srcWebp
            srcSetWebp
          }
        }
        videos {
          video_size
          video {
            document {
              ... on PrismicVideo {
                id
                uid
                data {
                  title {
                    text
                  }
                  brand {
                    text
                  }
                  vimeo_video {
                    html
                    embed_url
                    width
                    description
                    title
                    height
                  }
                  vimeo_mp4_file {
                    url
                  }
                  director {
                    uid
                    document {
                      ... on PrismicDirector {
                        id
                        data {
                          title {
                            text
                          }
                        }
                      }
                    }
                  }
                  thumbnail {
                    alt
                    fluid(imgixParams: { w: 2000 }) {
                      srcWebp
                      srcSetWebp
                    }
                  }
                }
              }
            }
          }
        }
        title {
          html
          text
        }
        bio {
          html
        }
      }
    }
  }
`;
