import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import LazyLoad from "react-lazyload";

// Components
import { ShortVideo } from "./short-video";
import { VimeoThumbnail } from "../images/vimeo-thumbnail";
import { Caption } from "../text/caption";

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  border-bottom: 2px solid #1d1d1b;

  grid-column-end: ${props => props.column};
  grid-row-end: ${props => props.rowColumn};

  & a {
    width: 100%;
    height: 100%;

    display: block;
  }

  @media (max-width: 768px) {
    grid-column-end: span 2;

    &.Landscape {
      grid-row-end: span 1;
    }

    &.Square {
      grid-row-end: span 2;
    }
  }
`;

const VideoWrapper = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ${props => props.ratio}%;
  }
  & > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & img {
    transition: 250ms opacity ease;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:hover img {
    opacity: ${props => (props.fadeOutImage ? 0 : 1)};
  }
`;

export const DirectorVideo = ({
  content,
  directorUid,
  index,
  isMobileDevice,
}) => {
  const [currentVideoPlayerId, setCurrentVideoPlayerId] = useState("");
  const [shouldImageFadeOut, setImageFadeOut] = useState(false);
  const [allowPointerEvents, setAllowPointerEvents] = useState("none");
  const [onReady, setOnReady] = useState(false);

  useEffect(() => {
    setAllowPointerEvents(`all`);
  }, [onReady, setAllowPointerEvents]);

  return (
    <VideoContainer
      key={`director_video_${directorUid}_${index}`}
      column={
        content.video_size === "Landscape" || content.video_size === null
          ? `span 1`
          : `span 1`
      }
      rowColumn={
        content.video_size === "Landscape" || content.video_size === null
          ? `span 1`
          : `span 2`
      }
      className={content.video_size}
      onMouseOver={() => setCurrentVideoPlayerId(content.video.document.id)}
      onMouseLeave={() => setCurrentVideoPlayerId("")}
    >
      <Link
        to={`/${content.video.document.uid}`}
        state={{
          videoLocation: `directors`,
        }}
      >
        <VideoWrapper
          ratio={
            index === 0
              ? `56.25`
              : content.video_size === "Landscape" ||
                content.video_size === null
              ? `56.25`
              : `100`
          }
          fadeOutImage={
            currentVideoPlayerId === content.video.document.id &&
            shouldImageFadeOut &&
            content.video.document.data.vimeo_mp4_file.url.length > 0
          }
          pointerEvents={allowPointerEvents}
        >
          <div className="content">
            {!isMobileDevice && (
              <LazyLoad height={400} offset={200}>
                <ShortVideo
                  video={content.video.document.data.vimeo_mp4_file.url}
                  width={1920}
                  height={1080}
                  playerId={`all_directors_video_${index}`}
                  embed_url={content.video.document.data.vimeo_video.embed_url}
                  setImageFadeOut={shouldImageFadeOut =>
                    setImageFadeOut(shouldImageFadeOut)
                  }
                  preload={index <= 5 ? "metadata" : "none"}
                  isPlaying={
                    currentVideoPlayerId === content.video.document.id
                      ? true
                      : false
                  }
                  setOnReady={setOnReady}
                  loadedSeconds={0.5}
                />
              </LazyLoad>
            )}

            <VimeoThumbnail
              width={1920}
              height={1080}
              prismicThumbnail={content.video.document.data.thumbnail.fluid}
              embed_url={content.video.document.data.vimeo_video.embed_url}
            />
          </div>
        </VideoWrapper>

        <Caption>
          {content.video.document.data.brand.text !== "" ? (
            <>
              <h2>{content.video.document.data.brand.text}</h2>
              <p>{content.video.document.data.title.text}</p>
            </>
          ) : (
            <h2>{content.video.document.data.title.text}</h2>
          )}
        </Caption>
      </Link>
    </VideoContainer>
  );
};
